<template>
    <div>
        <!-- <div style="font-size:23px;margin-top:50px;margin-bottom:20px">请您添加客服企业微信<br>随时为您提供咨询服务</div>
      <div>
          <img style="width:285px" src="./qrcode.png" alt="">
      </div>
      <div style="font-size:23px;margin-top:20px;">长按识别二维码</div> -->
    </div>
</template>

<script>
export default {
    created() {
        location.replace( 'https://work.weixin.qq.com/ca/cawcde5ac32acf3bcf');
    },
};
</script>

<style></style>
